import { Box } from "@chakra-ui/react"
import { useEffect } from "react"

const ReviewWrap = () => {
  useEffect(() => {
    // Load the Trustpilot widget script
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src =
      "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
    script.async = true
    document.head.appendChild(script)

    return () => {
      // Cleanup script on unmount if needed
      if (document.head.contains(script)) {
        document.head.removeChild(script)
      }
    }
  }, [])

  return (
    <Box bg="white" borderRadius="md" boxShadow="sm" p={2}>
      {/* TrustBox widget - Micro TrustScore */}
      <div
        className="trustpilot-widget"
        data-locale="fr-FR"
        data-template-id="5419b637fa0340045cd0c936"
        data-businessunit-id="6649bb08db9be3d2f14f8b21"
        data-style-height="20px"
        data-style-width="100%"
      >
        <a
          href="https://fr.trustpilot.com/review/bleu.care"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
      {/* End TrustBox widget */}
    </Box>
  )
}

export default ReviewWrap
