import {
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import _ from "lodash"
import { useMemo } from "react"
import { Link, Outlet, useNavigate, useParams } from "react-router-dom"
import { PatientResponses } from "@bleu/types/endpoints/patients"
import { DepistageName } from "@bleu/shared/depistages/index"
import { MedicalRecordStatuses } from "@bleu/shared/medicalRecords/statuses"
import { MedicalRecordButton } from "@bleu/front/components/doctor/MedicalRecordButton"
import { BackButton } from "@bleu/front/components/layout/BackButton"
import { DoctorLayout } from "@bleu/front/components/layout/DoctorLayout"

type Props = {
  patient: PatientResponses.Patient
}

export const PatientFile = ({ patient }: Props) => {
  const { recordId, patientId } = useParams()

  const navigate = useNavigate()

  const { closedRecords, openRecords } = useMemo(() => {
    return {
      closedRecords: _.orderBy(
        patient.medicalRecords.filter(
          (record) => record.status === MedicalRecordStatuses.CLOSED,
        ),
        (record) => record.createdAt,
        "desc",
      ),
      openRecords: _.orderBy(
        patient.medicalRecords.filter(
          (record) => record.status !== MedicalRecordStatuses.CLOSED,
        ),
        (record) => record.createdAt,
        "desc",
      ),
    }
  }, [patient.medicalRecords])

  const defaultTabIndex = useMemo(() => {
    if (recordId) {
      if (closedRecords.find((record) => record.id === recordId)) {
        return 1
      }
    }
    return 0
  }, [closedRecords, recordId])

  return (
    <Stack gap={12} w="100%" h="100%">
      <Stack gap={2}>
        <HStack gap={6}>
          <BackButton onClick={() => navigate("/doctor")} />
          <DoctorLayout.Title>{patient.fullName}</DoctorLayout.Title>
        </HStack>
        <Text fontSize="sm" color="gray.600" pl={16}>
          E-mail:{" "}
          <ChakraLink href={`mailto:${patient.emailAddress}`} color="blue.500">
            {patient.emailAddress}
          </ChakraLink>{" "}
          • Téléphone:{" "}
          <ChakraLink href={`tel:${patient.phoneNumber}`} color="blue.500">
            {patient.phoneNumber}
          </ChakraLink>
        </Text>
      </Stack>
      <HStack gap={5} align="flex-start" flex={1}>
        <Stack bgColor="white" borderRadius={8} py={4}>
          <Tabs w={260} size="sm" defaultIndex={defaultTabIndex}>
            <TabList>
              <Tab>Dossiers en cours</Tab>
              <Tab>Dossiers clotûrés</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack>
                  {!openRecords.length && (
                    <Text color="gray.700" fontSize="sm">
                      Aucun dossier.
                    </Text>
                  )}
                  {openRecords.map((record) => (
                    <Link
                      key={record.code}
                      to={`/doctor/patients/${patientId}/records/${record.id}`}
                    >
                      <MedicalRecordButton
                        isFreemium={record.isFreemium}
                        isSelected={record.id === recordId}
                        prescribedDepistages={
                          record.questionnaireAnswer
                            .prescribedDepistages as DepistageName[]
                        }
                        label={`${record.code} (${dayjs(record.createdAt).format("DD/MM/YYYY")})`}
                      />
                    </Link>
                  ))}
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack>
                  {!closedRecords.length && (
                    <Text color="gray.700" fontSize="sm">
                      Aucun dossier.
                    </Text>
                  )}
                  {closedRecords.map((record) => (
                    <Link
                      key={record.code}
                      to={`/doctor/patients/${patientId}/records/${record.id}`}
                    >
                      <MedicalRecordButton
                        isSelected={record.id === recordId}
                        prescribedDepistages={
                          record.questionnaireAnswer
                            .prescribedDepistages as DepistageName[]
                        }
                        label={`${record.code} (${dayjs(record.createdAt).format("DD/MM/YYYY")})`}
                      />
                    </Link>
                  ))}
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
        <DoctorLayout.Body>
          <Outlet />
        </DoctorLayout.Body>
      </HStack>
    </Stack>
  )
}
