import { Button, HStack, Icon, Text, VStack } from "@chakra-ui/react"
import { useMemo } from "react"
import {
  DepistageName,
  getVerticalNameForDepistageName,
} from "@bleu/shared/depistages/index"
import { VerticalName } from "@bleu/shared/verticals/index"
import { verticalsTheme } from "@bleu/front/assets/theme/verticalsTheme"

type Props = {
  label: string
  isSelected: boolean
  prescribedDepistages: DepistageName[]
  isFreemium?: boolean
}

export const MedicalRecordButton = ({
  label,
  isSelected,
  prescribedDepistages,
  isFreemium = false,
}: Props) => {
  const vertical = useMemo(() => {
    const verticalNames = [
      ...new Set(
        prescribedDepistages.map((depistage) =>
          getVerticalNameForDepistageName(depistage),
        ),
      ),
    ]
    const verticalName =
      verticalNames.length === 1 ? verticalNames[0] : VerticalName.GENERAL
    return verticalsTheme[verticalName]
  }, [prescribedDepistages])

  return (
    <Button
      w="100%"
      h="100%"
      variant="unstyled"
      borderRadius={8}
      borderWidth={1}
      borderColor={isSelected ? "blue.400" : "blue.300"}
      bgColor={isSelected ? "blue.200" : "blue.50"}
      _hover={{ bgColor: "blue.200" }}
      px={4}
      py={isFreemium ? 3 : 2}
    >
      <HStack width="100%" spacing={4} align="center">
        <Icon boxSize={5} as={vertical.icon} color={vertical.color} />
        <VStack width="100%" spacing={isFreemium ? 2 : 0} align="stretch">
          <Text fontWeight="bold" fontSize="xs">
            {label}
          </Text>
          {isFreemium && (
            <Text
              fontSize="xs"
              px={2}
              py={0.5}
              borderRadius="full"
              bg="gray.100"
              color="gray.600"
              width="fit-content"
            >
              Freemium
            </Text>
          )}
        </VStack>
      </HStack>
    </Button>
  )
}
